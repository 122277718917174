.cardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cardContainer {
  display: flex;
  min-width: 239px;
  margin: 10px;
  padding: 12px;
  /* border: 1px solid rgb(82, 82, 82); */
  color: black !important;
  cursor: pointer;
  /* filter: grayscale(1); */
  background-color: rgb(224, 224, 224) !important;
  border-radius: 5px;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
  transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.cardContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  /* filter: grayscale(0); */
  transform: translateY(-3px); /* Use translateY instead of translate */
}

.cardContainer:hover {
  transform: translate(0, -5px);
  /* background-color: #c16565;
    -webkit-box-shadow: 0px 0px 5px 0px #c16565; */
  /* box-shadow: 0px 0px 5px 0px #c16565; */
}

.cardTitle {
  font-size: 20px;
  font-weight: 400;
  color: rgb(56, 56, 56);
  text-align: right;
}

.cardTopLogo {
  margin-bottom: 15px;
  text-align: right;
}

.cardLogo {
  align-items: flex-end;
  /* color: black !important; */
  font-size: 30px !important;
}

.userCount {
  margin-top: 4px;
  color: rgb(88, 172, 88);
  font-weight: 500;
  font-size: 20px;
  text-align: right;
}
.trendingLogo {
  margin-right: 6px;
  font-weight: 300 !important;
  font-size: 35px !important;
  color: white;
}

@keyframes glowing {
    0% { text-shadow: 0 0 10px white; }
    50% { text-shadow: 0 0 40px white; }
    100% { text-shadow: 0 0 10px white; }
}

.glowText{
    font-size: 22px;
    color: white;
    animation: glowing 2s infinite;
}