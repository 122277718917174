.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}
.container {
  margin: 0;
  padding: 0;
}
.logoImg {
  width: 10em;
  color: white;
  cursor: pointer;
  /* filter: grayscale(1); */

}


.spanName {
  color: white;
  font-size: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
}

.menuIcons {
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
li .MuiButtonBase-root:hover {
  background-color: #9c85aa;
}

.topIconbadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: #7d499d;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  filter: grayscale(1);
  border: 3px solid #d16060;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.topAvatar:hover{
  filter: grayscale(0);

}

.topRight {
  display: flex;
  align-items: center;
}
.topbarWrapper {
  height: 100%;
  width: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
