.updateBut1 {
    background: linear-gradient(to right, #bbd5bc 0%, #ffcccc 100%);
    color: rgb(12, 11, 11);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    padding: 5px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
.updateBut1.active{
    transform: scale(0.95);
    box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25);

}

.searchBarInput{
    border: none;
    font-size: 15px;
    outline: none;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 100%;
}
.userUpdateForm1 {
   
    margin-top: 40px;
}
.eyeIcon{
    font-size: 15px !important;  
}
.eyeIcon:hover{
    cursor: pointer; 
}
.userInput12{
    font-size: 15px;
    /* margin-left: 40px; */
    font-weight: 500;
    min-width: 524px;
    border-radius: 4px;
    border: 1px solid #dbb3b3;
    color: rgb(41, 40, 40);
    margin-right: 5px;
    padding: 3px;
   
}

.eyeButton{
    width: 10px;
    height: 10px;
    border: none;
    background-color: rgb(170, 137, 94);
}
.inputA{
 display: flex;
 align-items: center;
    background-color: #c4d0d0;
    border-radius: 5px;
    padding-right: 5px;
    margin-left: 20px;  
}
.eyeIcon.active{
    transform: scale(0.95);
    /* box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25); */
}
