/* .home{
    flex: 4;
    padding: 20px;
} */
.homeWidget{
    display: flex;
    margin: 20px;
}
.link{
    text-decoration: none;
    color: inherit;
    }